<template>
  <div class="admin-influencers-form large-page">
    <!-- show the breadcrumbs and the button to trigger form -->
    <breadcrumbs>
      <div class="d-flex flex-grow-1 justify-end align-center">
        <!-- Search input -->
        <v-text-field
          v-model="filters.q"
          dark
          dense
          outlined
          hide-details
          label="Search Influencer"
          class="mr-3 mr-md-6 search-input"
          append-icon="search"
          @click:append="triggerSearch"
          @keypress.enter="triggerSearch"
        />

        <!-- Upload Influencer Sheet -->
        <v-btn
          depressed
          class="mr-3"
          color="white"
          @click="shouldShowUploadDialog = true"
        >
          <v-icon color="secondary">
            file_upload
          </v-icon>
        </v-btn>

        <!-- Show all the columns in the table -->
        <v-menu
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              class="mr-3"
              color="white"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                filter_alt
              </v-icon>
            </v-btn>
          </template>

          <!-- For each column item, show a list item -->
          <v-list>
            <v-list-item
              v-for="item in columns"
              :key="item.value"
            >
              <v-list-item-action>
                <v-simple-checkbox
                  v-model="item.enabled"
                  color="primary"
                />
              </v-list-item-action>

              <v-list-item-title
                class="cursor-pointer"
                @click="item.enabled = !item.enabled"
              >
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Show the sort options -->
        <v-menu
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              color="white"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                swap_vert
              </v-icon>
            </v-btn>
          </template>

          <v-card max-width="180">
            <v-card-text class="pb-0">
              <!-- Show the inputs to sort it with -->
              <v-select
                label="Sort By"
                v-model="filters.sortBy"
                :items="sortByOptions"
                @change="handleSortOptions"
              />

              <!-- Show the inputs to order it by -->
              <v-select
                label="Sort Order"
                v-model="filters.sortOrder"
                :items="sortOrderOptions"
                @change="handleSortOptions"
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </breadcrumbs>

    <!-- Show the results -->
    <v-data-table
      v-if="apiResponse"
      :headers="tableHeaders"
      :items="apiResponse.data"
      :server-items-length="apiResponse.total"
      class="mt-4 box-shadow-soft"
      @update:page="handlePagination"
      @update:items-per-page="handlePerPage"
    >
      <!-- Show a platform selector in header -->
      <template v-slot:header.id>
        <div>
          <platform-selector
            dark
            small
            v-model="filters.platform"
            @change="triggerSearch"
          />
        </div>
      </template>

      <!-- Show the user's profile card -->
      <template v-slot:item.id="{ item }">
        <div class="d-flex align-center">
          <span class="mr-3">
            {{ item.id }}
          </span>

          <profile-chip
            :platform="item.platform"
            :data="item.preview || { picture: null, username: item.username, fullname: item.name }"
          />
        </div>
      </template>

      <!-- Show editable name column -->
      <template v-slot:item.name="{ item }">
        <div>
          <v-edit-dialog
            :return-value.sync="item.name"
            @save="saveItem(item)"
          >
            <div class="text-subtitle-2 font-weight-medium mt-1 line-height-1">
              {{ item.name }}
            </div>

            <template v-slot:input>
              <v-text-field
                v-model="item.name"
                label="Name"
                style="max-width: 10em;"
                single-line
                counter="200"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </div>
      </template>

      <!-- Show editable email column -->
      <template v-slot:item.email="{ item }">
        <div>
          <v-edit-dialog
            :return-value.sync="item.email"
            @save="saveItem(item)"
          >
            <div class="text-subtitle-2 font-weight-medium mt-1 line-height-1">
              {{ item.email }}
            </div>

            <template v-slot:input>
              <v-text-field
                v-model="item.email"
                label="Email"
                single-line
                counter="200"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </div>
      </template>

      <!-- Show editable phone column -->
      <template v-slot:item.phone="{ item }">
        <div>
          <v-edit-dialog
            :return-value.sync="item.phone"
            @save="saveItem(item)"
          >
            <div class="text-subtitle-2 font-weight-medium mt-1 line-height-1">
              {{ item.phone }}
            </div>

            <template v-slot:input>
              <v-text-field
                v-model="item.phone"
                label="Phone Number"
                single-line
                counter="200"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </div>
      </template>

      <!-- Show editable followers column -->
      <template v-slot:item.followers="{ item }">
        <div>
          <v-edit-dialog
            :return-value.sync="item.followers"
            @save="saveItem(item)"
          >
            <div class="text-subtitle-2 font-weight-medium mt-1 line-height-1">
              {{ nFormatter(item.followers) }}
            </div>

            <template v-slot:input>
              <v-text-field
                v-model="item.followers"
                label="Followers"
                single-line
                type="number"
                style="max-width: 7em;"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </div>
      </template>

      <!-- Show editable combobox for languages -->
      <template v-slot:item.languages="{ item }">
        <div>
          <v-edit-dialog
            :return-value.sync="item.languages"
            @save="saveItem(item)"
          >
            {{ item.languages ? item.languages.join(', ') : 'NA' }}

            <template v-slot:input>
              <v-combobox
                v-model="item.languages"
                label="Select Multiple"
                multiple
                chips
              ></v-combobox>
            </template>
          </v-edit-dialog>

        </div>
      </template>

      <!-- Show editable combobox for locations -->
      <template v-slot:item.locations="{ item }">
        <div>
          <v-edit-dialog
            :return-value.sync="item.locations"
            @save="saveItem(item)"
          >
            {{ item.locations ? item.locations.join(", ") : 'NA' }}

            <template v-slot:input>
              <v-combobox
                v-model="item.locations"
                label="Select Multiple"
                multiple
                chips
              ></v-combobox>
            </template>
          </v-edit-dialog>
        </div>
      </template>

      <!-- Show editable combobox for interests -->
      <template v-slot:item.interests="{ item }">
        <div>
          <v-edit-dialog
            @close="saveItem(item)"
          >
            {{ item.interests ? item.interests.join(", ") : 'NA' }}

            <template v-slot:input>
              <v-combobox
                v-model="item.interests"
                :items="interestOptions"
                label="Select Multiple"
                multiple
                chips
              ></v-combobox>
            </template>
          </v-edit-dialog>
        </div>
      </template>

      <!-- Show select options for the pricing -->
      <template v-slot:item.post_format="{ item }">
        <v-select
          :value="selectedPostFormat['item-' + item.id]"
          :items="postFormatOptions"
          class="post-format-select"
          @input="(value) => computeCostValueForRow(value, item)"
          hide-details
          single-line
          outlined
          dense
        />
      </template>

      <template v-slot:item.cost="{ item }">
        <div class="d-flex justify-end align-center">
          <span :key="refreshKey">
            {{ currencySymbolMap[item.currency] }}{{ item.cost }}
          </span>

          <!-- To show interests list -->
          <v-btn
            text
            depressed
            class="ml-3"
            color="secondary"
            @click="selectedItem = item; shouldShowCostsDialog = true;"
          >
            <v-icon>
              info
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- show the dialog form -->
    <v-dialog
      v-model="shouldShowUploadDialog"
      max-width="600"
    >
      <v-card
        :loading="isLoading"
      >
        <v-card-text class="pt-4 grey lighten-3">
          <v-form
            :disabled="isLoading"
          >
            <div class="d-flex flex-column align-center">
              <div class="secondary--text text-h5 font-weight-bold mb-4 mb-md-12">
                Upload Data
              </div>

              <v-icon
                size="80"
                class="grey--text text--darken-1"
              >
                upload_file
              </v-icon>
            </div>

            <label
              for="form-file-input"
              class="d-flex justify-center mt-4 mt-md-6 mt-lg-8 mb-4"
            >
              <v-file-input
                v-model="form.file"
                ref="fileInput"
                class="d-none"
                accept=".xlsx"
                @change="handleFileUpload"
              />

              <v-btn
                depressed
                color="primary"
                @click="triggerFileInput"
                :disabled="isLoading"
                :loading="isLoading"
              >
                Upload
              </v-btn>
            </label>

            <div class="font-weight-bold grey--text text--darken-1 text-h6 text-center">
              Only Excel File
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Show a dialog for influencer details -->
    <v-dialog
      v-model="shouldShowCostsDialog"
      max-width="300"
      scrollable
    >
      <v-card>
        <v-card-title>
          Cost Details
        </v-card-title>

        <v-card-text v-if="selectedItem">
          <v-list dense>
            <template
              v-for="(keyString, index) in Object.keys(costKeyMap[selectedItem.platform])"
            >
              <v-list-item :key="keyString">
                <v-list-item-icon class="mr-3">
                  <v-icon color="secondary">
                    {{ costKeyIconMap[keyString] }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="d-flex justify-space-between">
                    <span>
                      {{ costKeyMap[selectedItem.platform][keyString] }}
                    </span>

                    <v-edit-dialog
                      :return-value.sync="selectedItem[keyString]"
                      @save="saveItem(selectedItem)"
                    >
                      <span class="font-weight-bold">
                        {{ currencySymbolMap[selectedItem.currency] }}{{ nFormatter(selectedItem[keyString]) }}
                      </span>

                      <template v-slot:input>
                        <v-text-field
                          v-model="selectedItem[keyString]"
                          style="max-width: 5em"
                          type="number"
                          label="Cost"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider v-if="index < Object.keys(costKeyMap[selectedItem.platform]).length - 1"></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import node packages
import { required } from "vuelidate/lib/validators"

// @BUGFIX https://github.com/vuetifyjs/vuetify/issues/12224#issuecomment-894201049
import Ripple from 'vuetify/lib/directives/ripple'

// Import helper objects and functions
import costKeyMap from "@/helpers/costKeyMap.js"
import costKeyIconMap from "@/helpers/costKeyIconMap.js"
import currencySymbolMap from "@/helpers/currencySymbolMap.js"
import dataHelper from "@/helpers/dataHelper"

// Import local storage helper functions
import { localStorageManager, ADMIN_DATA } from "@/helpers/localStorageManager.js"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip.vue")
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Define the generator function
const defaultFormData = () => ({
  file: null
})

// Define a generator function for columns to be shown
const defaultColumns = () => ([
  {
    text: "ID",
    value: "id",
    sortable: false,
    enabled: true,
  },
  {
    text: "Followers",
    value: "followers",
    sortable: false,
    enabled: true,
  },
  {
    text: "Name",
    value: "name",
    sortable: false,
    enabled: true,
  },
  {
    text: "Email ID",
    value: "email",
    sortable: false,
    enabled: true,
  },
  {
    text: "Phone No.",
    value: "phone",
    sortable: false,
    enabled: true,
  },
  {
    text: "Locations",
    value: "locations",
    sortable: false,
    enabled: true,
  },
  {
    text: "Languages",
    value: "languages",
    sortable: false
  },
  {
    text: "Interests",
    value: "interests",
    sortable: false,
    enabled: true,
  },
  {
    text: "Post Format",
    value: "post_format",
    sortable: false,
    enabled: true,
  },
  {
    text: "Cost Per Post",
    value: "cost",
    sortable: false,
    enabled: true,
  },
])

// Export the SFC
export default {
  // Name of the component
  name: "AdminInfluencersForm",

  // Register the children components
  components: {
    ProfileChip,
    Breadcrumbs,
    PlatformSelector
  },

  // Register vue directives
  directives: {
    Ripple
  },

  // Define local data variables
  data: () => ({
    costKeyMap,
    costKeyIconMap,
    currencySymbolMap,

    refreshKey: Symbol(),

    isLoading: false,
    shouldShowUploadDialog: false,

    selectedItem: null,
    shouldShowCostsDialog: false,

    interests: [],

    // API search filters
    filters: {
      q: "",
      page: 1,
      perPage: 10,
      sortBy: "id",
      sortOrder: "desc",
      platform: "instagram"
    },

    sortByOptions: [
      {
        text: "ID",
        value: "id"
      },
      {
        text: "Name",
        value: "name"
      },
      {
        text: "Followers",
        value: "followers"
      },
      {
        text: "Engagement Rate",
        value: "engagement_rate"
      }
    ],

    sortOrderOptions: [
      {
        text: "Ascending",
        value: "asc"
      },
      {
        text: "Descending",
        value: "desc"
      }
    ],

    form: defaultFormData(),
    columns: defaultColumns(),

    apiResponse: null,

    selectedPostFormat: {},
  }),

  // Define computable readonly variables
  computed: {
    /**
     * Get the selected post type's cost options for the row
     *
     * @returns {Object}
     */
    postFormatOptions() {
      return Object.keys(costKeyMap[this.filters.platform]).map((key) => ({
        text: costKeyMap[this.filters.platform][key],
        value: key
      }))
    },

    /**
     * Return a list of columns to be shown
     *
     * @returns {Array}
     */
    tableHeaders() {
      return this.columns.filter((item) => item.enabled)
    },

    interestOptions() {
      return this.interests.map((item) => item.name)
    }
  },

  // Set watchers for data properties
  watch: {
    columns: {
      handler: (items) => {
        // Save it into the localStorage
        localStorageManager({
          name: ADMIN_DATA,
          key: "influencerColumns",
          value: items
        })
      },

      deep: true,
      immediate: false
    }
  },

  // Define vuelidate input validations object
  validations: {
    form: {
      file: {
        required,
        maxSize: (value) => {
          return value ? value.size <= 40_000_000 : false
        },
        mimeType: (value) => {
          return value ?
            [
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ].includes(value.type) : false
        }
      },
    }
  },

  // Define Options API method functions
  methods: {
    /**
     * Go through each row and calculate the current cost value
     *
     * @returns {void}
     */
    computeCostColumns() {
      // Map all items in data to select the default cost option
      for (const item of this.apiResponse.data) {
        this.computeCostValueForRow(Object.keys(costKeyMap[this.filters.platform])[0], item)
      }
    },

    /**
     * Make the network request to fetch paginated data
     *
     * @returns {Promise<void>}
     */
    async fetchItems() {
      // Create the loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        const queryParams = new window.URLSearchParams(this.filters)

        const response = await axios({
          url: "/api/admin/influencers?" + queryParams
        })

        // Show the data as a table view
        this.apiResponse = response.data

        // Update the value for selected post format
        this.computeCostColumns()
      } catch (error) {
        // log using the helper function
        logger({ type: "Network Error", error })
      } finally {
        // close the loader
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Process the user file upload action
     *
     * @returns {Promise<void>}
     */
    async handleFileUpload() {
      await this.$v.$touch()

      // if the validation fails, don't continue
      if (this.$v.$anyError) return false

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      try {
        const formData = new FormData()
        formData.append("sheet", this.form.file)

        await axios({
          url: "/api/admin/influencers",
          method: "POST",
          data: formData
        })

        this.$store.dispatch("toasts/add", { text: "Uploaded successfully" })

        // Hide the dialog
        this.shouldShowUploadDialog = false
        this.form.file = null

        // Fetch the items
        this.fetchItems()
      } catch (error) {
        logger({ type: "Upload Influencers Spreadsheet Error", error })

        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      } finally {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    },

    /**
     * Make the network request to save the edited data
     *
     * @param {Object} item
     */
    async saveItem(item) {
      this.isLoading = true

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        await axios({
          url: `/api/admin/influencers/${item.id}`,
          method: "PUT",
          data: item
        })

        // Once saved, also re-update cost values
        this.computeCostColumns()
      } catch (error) {
        logger({ type: "Influencer Update Error", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }

      // Once all requests have been made, remove the loader
      this.$store.dispatch("loaders/remove", loaderId)

      // Reset the local state
      this.isLoading = false
    },

    /**
     * Update the pagination page number and fetch from API
     *
     * @param {Number} value
     * @return {void}
     */
    handlePagination(value) {
      this.filters.page = value
      this.fetchItems()
    },

    /**
     * Update the pagination per page value and fetch from API
     *
     * @param {Number} value
     * @returns {void}
     */
    handlePerPage(value) {
      this.filters.perPage = value
      this.fetchItems()
    },

    /**
     * Re-fetch items with search query
     *
     * @returns {void}
     */
    triggerSearch() {
      // Reset the page number
      this.handlePagination(1)

      // Make the network request
      this.fetchItems()
    },

    /**
     * Re-fetch items with sort filters
     *
     * @returns {void}
     */
    handleSortOptions() {
      // Make the network request
      this.fetchItems()
    },

    /**
     * Fire the click even on the file input when button is clicked
     *
     * @returns {void}
     */
    triggerFileInput() {
      return this.$refs.fileInput?.$el?.querySelector("input")?.click() || false
    },

    /**
     * Get and set the cost column value based on the selected option
     *
     * @param {Number} value
     * @param {Object} item
     */
    computeCostValueForRow(value, item) {
      this.selectedPostFormat['item-' + item.id] = value

      item.cost = item[value] ? nFormatter(item[value]) : 'NA'
      this.refreshKey = Symbol()
    }
  },

  /**
   * As soon as the component is ready
   *
   * @returns {void}
   */
  async created() {
    // Check if the columns are there in the localStorage
    const items = localStorageManager({ name: ADMIN_DATA })?.influencerColumns

    if (items && items.length) {
      this.columns = items
    }

    // Fetch the API response
    this.fetchItems()

    // Fetch and store interests in the store
    this.interests = await dataHelper.loadData("interests.json")
  },

  /**
   * Before the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Unload the data
    dataHelper.unloadData("interests.json")
  }
}
</script>

<style lang="stylus" scoped>
.search-input
  max-width 20em

.post-format-select
  min-width 6.5em
</style>

<style lang="stylus">
.admin-influencers-form th, .admin-influencers-form td
  white-space nowrap
</style>
